// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
/* ---------------------- Touch ----------------------*/
    /* BANNER SLIDER*/
    jQuery(".banner-slider").slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        dots: false,
        arrows: false,
        fade:true,
        autoplay: true,
        autoplaySpeed: 8000,
        speed: 1000,
        cssEase: "linear",
    });
});


